import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "../cards/card";
import { getBucketUrl } from '../../service';
import ConditionalLinkWrapper from '../../components/conditionalLinkWrapper';

const CardsCarousel = props => {
  const [infosFiltered, setInfosFiltered] = useState([])

  const settings = {
    className: 'c-cards-slider',
    infinite: false,
    dots: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3.2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.2
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2
        }
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1
        }
      }
    ],
  }

  function getLink(res) {
    if (props.hasLink) {
      if(res.hasOwnProperty("link")) {
        if (!res.hasOwnProperty("slug") || (res.link !== null && res.link !== "" && res.hasOwnProperty("slug")))
        return res.link;
      }

      if(res.hasOwnProperty("slug")) {
        return (props.path ? props.path : "") + res.slug;
      }
    }
    return null;
  }

  useEffect(() => {
    setInfosFiltered(props.cards)
  }, [props.cards])

  return (
    <>
      <div className="u-wrapper u-wrapper--carousel u-wrapper--mb">
        {infosFiltered.length > 0 && (
          <Slider {...settings}>
            {infosFiltered.map((result, index) => (
              <ConditionalLinkWrapper link={getLink(result)} key={index}>
                <Card
                  modifier={props.modifier || "carousel"}
                  data={result}
                  icon={result.icon ? getBucketUrl(result.icon.path) : null}
                  image={result.thumb ? getBucketUrl(result.thumb.path) : null}
                  year={result.year ? result.year : null}
                />
              </ConditionalLinkWrapper>
            ))}
          </Slider>
        )}
      </div>
    </>
  )
}

export default CardsCarousel
